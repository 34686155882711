<template>
    <div>
        <div ref="printPdf">
            <div>
                <div style="width: 220px; display: flex;justify-content: center; margin: 0 auto;">
                    <img src="../../../assets/img/BAU_Logo.png" height="130" ref="logoImage"/>
                </div>
            </div>

            <h1 style="text-align: center;margin-bottom: 10px">Bay Atlantic Universtiy (BAU)</h1>
            <div>
                <h6>{{ $t('enrollment_agreement_form') | toUpperCase }}</h6>
            </div>
            <div style="font-weight: normal;margin-bottom: 12px" class="font-weight-normal mb-3 ">
                {{ $t('enrollment_agrement_form_text') }}
            </div>
            <h4>{{ $t('Your Personel Information') }}</h4>
            <div>
                <label ><strong>Name</strong></label>
                <div>{{ registrationForm.name ? registrationForm.name : null }}
                    {{ registrationForm.surname ? registrationForm.surname : null }}
                </div>
                <label><strong>Date Of Birth</strong></label>
                <div> {{ registrationForm.birthdate ? toLocaleDate(registrationForm.birthdate) : null }}</div>
              <h4>{{ $t('your_mailing_us_address') }}</h4>
                <label><strong>State</strong></label>
                <div> {{ registrationForm.contact_city_name ?  registrationForm.contact_city_name: null }}</div>
                <label><strong>City</strong></label>
                <div> {{ registrationForm.contact_district_name ?  registrationForm.contact_district_name: null }}</div>

              <label><strong>Street</strong></label>
              <div> {{ registrationForm.contact_address ?  registrationForm.contact_address: null }}</div>
              <label><strong>Apartment</strong></label>
              <div> {{ registrationForm.apartment ?  registrationForm.apartment: null }}</div>
              <label><strong>Zip Code</strong></label>
              <div> {{ registrationForm.address_zip_code ?  registrationForm.address_zip_code: null }}</div>
            </div>
            <div style="display: flex;flex-direction: column">
              <div>
                <h4><strong>Your Home Address</strong></h4>
              </div>

              <label><strong>Country</strong></label>
              <div>{{ registrationForm.address_country_name ? registrationForm.address_country_name : null }}</div>

                <label><strong>Address</strong></label>
                <div>{{ registrationForm.address ? registrationForm.address : null }}</div>



            </div>
            <div>
              <div>
                <h4><strong>Contact Information</strong></h4>
              </div>


              <div style="display: flex;flex-direction: column">
                    <label> <strong>Email</strong></label>
                    <div>{{ registrationForm.email ? registrationForm.email : null }}</div>

                    <label><strong>Mobil Number</strong></label>
                    <div> {{ registrationForm.mobile_tel ? registrationForm.mobile_tel : null }}</div>

                    <label><strong>Other Phone</strong> </label>
                    <div>{{ registrationForm.us_mobile_tel ? registrationForm.us_mobile_tel : null }}</div>
                </div>
            </div>
            <div>
                <div>
                    <h4>Emergency Contact Information</h4>
                    <label> <strong>Name</strong></label>
                    <div> {{ registrationForm.emergency_person_name ? registrationForm.emergency_person_name : null }}
                    </div>

                    <label><strong>Relationship to student</strong></label>
                    <div>{{
                            registrationForm.emergency_person_relation ? registrationForm.emergency_person_relation : null
                        }}
                    </div>

                    <label><strong>Telephone Number</strong></label>
                    <div> {{
                            registrationForm.emergency_person_phone ? registrationForm.emergency_person_phone : null
                        }}
                    </div>

                    <label><strong>Your Legal Status</strong></label>
                    <div> {{
                            registrationForm.legal_presence_type ? registrationForm.legal_presence_type : null
                        }}
                    </div>
                </div>
                <div>
                    <div style="margin-right: 10px">I confirm that the information given in this form
                        is true, complete and accurate
                    </div>
                    <input type="checkbox" checked disabled>
                </div>
            </div>
            <div>
                <div>
                    <h4>Program Information</h4>
                    <label>
                        <strong>Date of Admission</strong>

                    </label>
                    <div> {{ registrationForm.registration_date ? registrationForm.registration_date : null }}</div>
                    <label><strong>Program</strong></label>
                    <div> {{ registrationForm.program_name ? registrationForm.program_name : null }}</div>

                    <label> <strong>Program Start Date</strong></label>
                    <div> {{ registrationForm.registration_semester_name ? registrationForm.registration_semester_name : null }}</div>

                    <label> <strong>Anticipated End Date</strong></label>
                    <div>
                        {{ studentData.anticipated_graduation_semester_name ? studentData.anticipated_graduation_semester_name : null }}
                    </div>
                    <label> <strong>Enrollment Status</strong></label>
                    <div> {{
                            registrationForm.registration_type_text ? registrationForm.registration_type_text : null
                        }}
                    </div>
                    <label><strong>Total Credit Required for Graduation</strong></label>
                    <div>
                        <span v-if="registrationForm.total_credit_required_for_graduation &&  registrationForm.credit">
                            {{
                                registrationForm.total_credit_required_for_graduation ? registrationForm.total_credit_required_for_graduation : null
                            }}
                            /
                            {{
                                registrationForm.credit?registrationForm.credit:null
                            }}
                        </span>

                        <span v-else>
                            {{
                                registrationForm.total_credit_required_for_graduation ? registrationForm.total_credit_required_for_graduation : null
                            }}
                        </span>
                    </div>
                </div>
                <div>
                    <div style="margin-right: 10px">I confirm that the information given in this form
                        is true, complete and accurate
                    </div>
                    <input type="checkbox" checked disabled>
                </div>
            </div>
            <div>
                <div>
                    <h4>Tuition and Fees</h4>
                </div>
                <div style="margin-bottom: 15px">
                    <table>
                        <tr>
                            <td><strong>Mandatory Semester Fees</strong></td>
                        </tr>

                        <tr>
                            <td>Student Activities and Services Fee</td>
                            <td>$135</td>
                        </tr>
                        <tr>
                            <td>Technology Fee</td>
                            <td>$165</td>
                        </tr>
                        <tr>
                            <td><strong>Mandatory One-Time Fee</strong></td>
                        </tr>
                        <tr>
                            <td>Student ID card</td>
                            <td>$20</td>
                        </tr>
                        <tr>
                            <td><strong>As-Applicable Fees (Paid only when students use these services)</strong></td>
                        </tr>
                        <tr>
                            <td>Late Registration Fee (per credit)</td>
                            <td>$25</td>
                        </tr>
                        <tr>
                            <td>Late Payment Fee (for each late / per credit)</td>
                            <td>$25</td>
                        </tr>
                        <tr>
                            <td>BAU English Proficiency Test</td>
                            <td>$45</td>
                        </tr>
                        <tr>
                            <td>Replacement Student ID Card $</td>
                            <td>$20</td>
                        </tr>

                        <tr>
                            <td>Transcript Fee (per transcript)</td>
                            <td>$20</td>
                        </tr>
                        <tr>
                            <td>Returned Check Fee</td>
                            <td>$35</td>
                        </tr>
                        <tr>
                            <td>Cancellation Fee*</td>
                            <td>$100</td>
                        </tr>
                        <tr>
                            <td>International Postage of Documents</td>
                            <td>$130</td>
                        </tr>
                        <tr>
                            <td>Administrative Services Fee**</td>
                            <td>$2000
                            </td>
                        </tr>
                        <tr>
                            <td>Student Housing Security Deposit</td>
                            <td>$350
                            </td>
                        </tr>
                        <tr>
                            <td>Graduation Fee</td>
                            <td>$200
                            </td>
                        </tr>
                        <tr>
                            <td>Diploma Processing Fee</td>
                            <td>$150
                            </td>
                        </tr>
                        <tr>
                            <td>Diploma Replacement Fee</td>
                            <td>$150
                            </td>
                        </tr>
                        <tr>
                            <td>Tuition per credit upon initial enrollment</td>
                            <td>${{registrationForm.tuition_per_credit}}</td>
                        </tr>

                    </table>
                </div>
                <div style="margin-bottom: 5px">
                    * When students cancel their enrollment within 3 business days of the beginning of a
                    semester.
                    **Only students who receive full scholarship of any kind defined in the scholarship section
                    are
                    required to pay.
                    Tuition and fees are not kept stable for cohorts upon registration. Tuition and fees may
                    change
                    each year based on the Board of Trustee’s decision. Students are required to check the
                    webpage
                    and the academic catalog during course registration.
                </div>
                <div>
                    <div style="margin-right: 10px">I confirm that the information given in this form
                        is true, complete and accurate
                    </div>
                    <input type="checkbox" checked disabled>
                </div>
            </div>
            <div>
                <div>
                    <h4>Scholarship/Tuition Assistance</h4>
                </div>
                <div>
                    Total of {{registrationForm.total_discount_per_credit}} USD for {{registrationForm.total_credit_required_for_graduation??null}} <span v-show="registrationForm.credit">/ {{registrationForm.credit??null}}</span>   credits. <span style="color: red">The </span>Per credit amount (total scholarship/tuition assistance divided
                    by
                    total credits) is applied to the credits enrolled during each course registration period. Repeat
                    credits and additional credits are exempt of the use of such scholarship/tuition assistance credits.
                    Students who earn a scholarship of any category should meet the following criteria to maintain the
                    scholarship:<br><br>
                    1) Continuously enroll in courses to meet graduation requirements (unless taking an approved
                    Leave of Absence), and<br>
                    2) Maintain a minimum Cumulative Grade Point Average (CGPA) of 2.0 at Undergraduate and 3.0
                    at Graduate level for any semester, and<br>
                    3) Avoid any disciplinary action for any reason.<br><br>
                    Scholarships/tuition assistance are available using the university’s funds allocated for students
                    who
                    need additional financial aid. That is why continuing one’s studies at BAU is important as another
                    student might have been rejected at the time of one’s application. Therefore, if a student who was
                    awarded any scholarship/tuition assistance withdraw from BAU for any reason, they are required to
                    pay the award amount back for all course credits they have completed since the
                    scholarship/tuition assistance was awarded to them. BAU is committed to re-use these funds for
                    students who need financial aid.
                </div>
                <div>
                    <label style="margin-right: 10px">I confirm that the information given in this form
                        is true, complete and
                        accurate </label>
                    <input type="checkbox" checked disabled>
                </div>

            </div>
            <div>
                <div>
                    <h4>Cancellation Refund Policy</h4>
                </div>
                <div style="margin-bottom: 10px">
                    If a student elects to withdraw from specific course(s) or completely from the university, the
                    following refund schedule will be used to determine any outstanding financial obligation for which
                    the student may be responsible. Refunds exclude all mandatory fees and deposits, which are
                    nonrefundable.

                </div>
                <div style="margin-bottom: 15px">
                    <table>
                        <tr>
                            <th>Time of written notice of withdrawal</th>
                            <th>Tuition refund*</th>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td><span style="color:red">Up until three business days prior to 1st day of the semester</span></td>
                            <td>100% of tuition
                                paid
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="width: 50%">From within 3 business days of the start of the semester through 25%
                                of the semester
                            </td>
                            <td>50% of tuition
                                paid
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td>From 25% through 50% of the semester</td>
                            <td>25% of tuition</td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td>After 50% of the semester</td>
                            <td>paid No refund</td>
                        </tr>
                    </table>
                </div>
                <div style="margin-bottom: 10px">
                    A student applicant will be considered a student as of the first day of classes. Request for withdrawal must be initiated on <span style="color: red">MyBAU</span> or submitted directly to the registrar to officially withdraw from BAU. No refund will be honored without the withdrawal request initiated on <span style="color: red">MyBAU</span> or submitted to the registrar. <strong>The official withdrawal date, for the purpose of a refund calculation, will be taken from the date the online request is received on <span style="color: red">MyBAU</span> or by the registrar.</strong><br><br>

                    BAU will issue refunds to individuals who have terminated their status as students within 45 days after receipt of the online withdrawal request. If no payment was made, the University will bill the student for the due amount according to the schedule above. If a student is participating in a payment plan and the installment payment is insufficient to cover the student's obligation according to the schedule above, then the university will send the student a bill for the difference.


                </div>
                <div>
                    <div style="margin-right: 10px">I confirm that the information given in this form
                        is true, complete and accurate
                    </div>
                    <input type="checkbox" checked disabled>
                </div>

            </div>
            <div>

                <div>
                    <h4>Financial Penalties, Part I</h4>
                </div>
                <div>
                    Financial good standing and a university record clear of holds are required for students to receive services.
                    Services, including but not limited to access to course, transcript issuance, diploma release, class registration (add, drop, withdrawal, etc.), and/or housing will not be provided to students with a financial balance due or a hold of any kind on their record. Holds are based on outstanding obligations and may be financial.
                    Students in noncompliance with payment deadlines will result in the immediate suspension of services.
                    Failure to meet financial obligations to the university will result in other collection procedures, which include account referral to credit reporting bureaus, private collection agencies, and DC Office of Tax and Revenue, and Office of Attorney General. Past due accounts are subject to garnishments, liens, and judgments and the withholding of money from tax refunds. In addition to late fees and interest, delinquent accounts will be assessed additional collection costs up to thirty percent of the past due balance, reasonable attorney fees, and other administrative costs. Once an account is referred to a collection agency payment must be made to the agency, not the University.<br>
                    No refund will be honored without the withdrawal request initiated on <span style="color: red">MyBAU</span>. Applicants must also submit a Refund Request Form on <span style="color: red">MyBAU</span> within 12 months of the initial application date.

                </div>
                <br>

                <div>
                    <div style="margin-right: 10px">I confirm that the information given in this form
                        is true, complete and accurate
                    </div>
                    <input type="checkbox" checked disabled>
                </div>

            </div>
            <div>

                <div>
                    <h4>Financial Penalties, Part II</h4>
                </div>
                <div>
                    If a student's financial obligation is not fulfilled, BAU is authorized to do the following until the money owed is paid:<br>

                    1) Students will not be able to register for the semester courses if they have not met their financial obligations. In addition, they will not be cleared for graduation, receive official transcripts or their diploma.<br>
                    2) Course grades for those students who have not completed their financial requirements until <span style="color: red">the end (missing space) </span> of the final exam week will not be entered. This means the credits for these courses will not be earned until the payments are made.<br>
                    3) BAU withholds the release of the student's academic records or any information on the records.<br>
                    4) BAU withholds the issuance of the student's transcripts.<br>
                    5) If the student's account remains delinquent, BAU reserves the right to terminate enrollment

                </div>
                <br>
                <div>
                    <div style="margin-right: 10px">I confirm that the information given in this form
                        is true, complete and accurate
                    </div>
                    <input type="checkbox" checked disabled>
                </div>


            </div>
            <div>
                <div>
                    <h4>Financial Penalties, Part III</h4>
                </div>
                <div>
                    <strong>1. Late Fee:</strong> Failure to make any payment on or before the due date results in $25
                    per credit per
                    month. The late fee will apply only to the tuition. Other fees (for example, activity fee,
                    technology
                    fee, etc.) will be deducted before applying payment for tuition.<br><br>
                    <strong> 2. Return Check Fee: </strong> A $35 fee will be charged for each unpaid check returned by
                    the bank.
                    Moreover, BAU will recalculate the late fee until the balance is cleared<br><br>

                    <strong>3. Special Cases:</strong> In the documented event of prolonged illness, accident, or death
                    in the
                    immediate family (parents, siblings, children, or spouse) or other special circumstances that make
                    it impractical to complete the program, BAU will work toward a settlement that is reasonable and
                    fair to both parties.
                </div>
                <br>
                <div>
                    <div style="margin-right: 10px">I confirm that the information given in this form
                        is true, complete and accurate
                    </div>
                    <input type="checkbox" checked disabled>
                </div>

            </div>
            <div>
                <div>
                    <h4>Notice to Student</h4>
                </div>
                <div>
                    a. This agreement is a legally binding document.<br>
                    b. You have accepted all the previous information by electronically signing up to this stage.<br>
                    c. You are entitled to an exact copy of this agreement and any disclosure pages you electronically
                    sign.<br>
                    d. This agreement and the academic catalog posted on the website of BAU constitute the entire
                    agreement between the student and BAU.<br>
                    e. BAU reserves the right to reschedule the program start date when the number of students
                    scheduled is too small.<br>
                    f. BAU reserves the right to terminate a student's enrollment for unsatisfactory progress,
                    nonpayment of tuition, or failure to abide by established standards of conduct.<br>
                    g. BAU does not guarantee the transferability of credits to a college, university, or institution.
                    Any
                    decision on the comparability, appropriateness, and applicability of credit and whether they should
                    be accepted is the decision of the receiving institution.<br>
                    h. BAU reserves the right to make changes to policies. When doing so, BAU makes announcements
                    to students via email, website updates, and/or posters on the campus. It is the responsibility of
                    the
                    students to check their emails, the website, and the Academic Catalog.

                </div>
                <br>
                <div>
                    <div style="margin-top: 5px;margin-right: 10px">I confirm that the information given in this form
                        is true, complete and accurate
                    </div>
                    <input type="checkbox" checked disabled>
                </div>
            </div>
            <div>
                <div>
                    <h4>Student's Acknowledgement</h4>
                </div>
                <div>
                    1. I hereby acknowledge that I am responsible for reading the BAU Academic Catalog. The
                    Academic Catalog, which can be accessed through our website www.bau.edu, is included as part
                    of this enrollment agreement.<br>
                    2. I have carefully read and received an exact copy of this enrollment agreement.<br>
                    3. I understand that the school may terminate my enrollment if I fail to comply with attendance,
                    academic, and financial requirements or if I fail to abide by established standards of conduct, as
                    outlined in the Academic Catalog. While enrolled in the school, I understand that I must maintain
                    satisfactory academic progress as described in the Academic Catalog, and that my financial
                    obligation to the school must be paid in full before a certificate may be awarded.<br>
                    4. I understand that the school does not guarantee job placement to graduates upon program
                    completion or upon graduation.<br>
                    5. I understand the complaints, which cannot be resolved by direct negotiation with the school in
                    accordance with its written grievance policy, may be filed with the <strong>Higher Education Licensure
                    Commission 1050 First Street, NE – 5th Floor Washington, DC 20002</strong>
                </div>
            </div>
            <div>
                <div>
                    <h4>Contract Acceptance</h4>
                </div>
                <div>
                    I, the undersigned, have read and understood this agreement and acknowledge receipt of a copy.
                    It is further understood and agreed that this agreement supersedes all prior or contemporaneous
                    verbal or written agreements and may not be modified without the written agreement of the
                    student and the School Official. I also understand that if I default on this agreement, I will be
                    responsible for  <span style="color: red">The</span> payment of any collection fees or attorney fees incurred by BAU.<br><br>
                    My signature below signifies that I have read and understood all aspects of this agreement and I
                    have recognized my legal responsibilities regarding this contract. I acknowledge that I have
                    received a copy of this document.<br><br>

                    Signature of Student <br> {{ registrationForm.name ? registrationForm.name : null }}
                    {{ registrationForm.surname ? registrationForm.surname : null }} <br> Date:{{ getDate() }}<br><br>
                    Signature of School Official<br> Office of the Registrar Date:{{ getDate() }}
                </div>
                <br>
                <div>
                    <div style="margin-right: 10px">I confirm that the information given in this form
                        is true, complete and accurate
                    </div>
                    <input type="checkbox" checked disabled>
                </div>
            </div>

        </div>
        <div class="col-12 mt-5">
            <div class="mb-4 d-flex justify-content-center">
                <h6>
                    <b-button variant="primary" class="no-button" @click="changeToHtml()">{{
                            $t('submit')
                        }}
                    </b-button>
                </h6>
            </div>
        </div>
    </div>


</template>
<script>
import EnrollmentAgreementForm from "@/services/EnrollmentAgreementForm";
import {mapGetters} from "vuex";


export default {
    data() {
        return {
            registrationForm: {}
        }
    },
    computed: {
        ...mapGetters({
            studentData: "enrollmentAgreementForm/formData"
        })
    },
    created() {
        this.getData()
    },
    methods: {
        getBase64Image(img) {
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.crossOrigin = 'Anonymous';
                image.src = img;
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0);
                    const dataURL = canvas.toDataURL('image/png');
                    resolve(dataURL);
                };
                image.onerror = (error) => {
                    reject(error);
                };
            })
        },

        async changeToHtml() {
            const printPdf = this.$refs.printPdf;
            const logoImage = this.$refs.logoImage;
            const logoBase64 = await this.getBase64Image(logoImage.src);
            logoImage.src = logoBase64;
            const htmlString = printPdf.outerHTML;
            const base64String = btoa(unescape(encodeURIComponent(htmlString)));
            this.sendToBackend(base64String);
        },
        async getData() {
            let user = await JSON.parse(localStorage.getItem('user'));
            const response = await EnrollmentAgreementForm.getStudentInformation(user.id)
            let data = response.data.data
            this.registrationForm = {
                ...this.studentData,
                registration_date: data.registration_date,
                program_name: data.program_name,
                registration_semester_academic_year: data.registration_semester_academic_year,
                anticipated_end_date: data.anticipated_end_date,
                registration_type_text: data.registration_type_text,
                total_credit_required_for_graduation: data.total_credit_required_for_graduation,
                tuition_per_credit: data.tuition_per_credit,
                total_discount_per_credit: data.discount_per_credit*data.total_credit_required_for_graduation
            }
        },
        async sendToBackend(base64String) {
            const config = {
                html: base64String,
                contact_address:this.studentData.contact_address,
                contact_city_id:this.studentData.contact_city_id,
                contact_district_id:this.studentData.contact_district_id,
                address_zip_code:this.studentData.address_zip_code,
                apartment:this.studentData.apartment,
                address_country_id:this.studentData.address_country_id,
                address:this.studentData.address,
            }
          try {
            const response = await EnrollmentAgreementForm.sendForm(config);
            if (response.data.success===true) {
              this.$toast.success(this.$t('form_submission_successful'))
              this.$emit('sendSuccessForm', true)
              this.clearForm()
              this.$router.push('/dashboard');
            } else {
              this.$toast.success(this.$t('api.' + response.data.message));
              this.clearForm()
            }
          } catch (error) {
            this.$toast.error(this.$t('api.' + error.data.message));
          }
        },
        async clearForm() {
            await this.$store.dispatch('enrollmentAgreementForm/deleteFormData');
        },

    },


};
</script>
<style scoped>
@media print {
    body {
        -webkit-print-color-adjust: exact !important;
    }

    .no-button {
        display: none;
    }
}
</style>

